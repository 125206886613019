import { LoadingArea } from '@consigli/facade';
import { useProjectId, useProject, useMyUser, useOrganization } from '@consigli/hooks';
import {
  CeilingType,
  LayoutType,
  OrganizationType,
  ProjectAccess,
  ProjectRole,
  ServiceId,
  ServiceName,
  ServicePermissionName,
  // StructuralType,
} from '@consigli/types';
import React, { type FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import CeilingPlanIcon from '@/assets/images/services/ceiling-plan-icon.png';
import DueDiligenceIcon from '@/assets/images/services/due-diligence-icon.png';
import FloorPlanIcon from '@/assets/images/services/floor-plan-icon.png';
import OfferIcon from '@/assets/images/services/offer-icon.png';
import OperationsManagementIcon from '@/assets/images/services/operations-management-icon.png';
import ParkingIcon from '@/assets/images/services/parking-icon.png';
import PlantRoomIcon from '@/assets/images/services/plant-room-icon.png';
import ReportsIcon from '@/assets/images/services/reports-icon.png';
import SpaceIcon from '@/assets/images/services/space-analysis-icon.png';
// import StructuralIcon from '@/assets/images/services/structural-icon.png';
import TenantRequirementIcon from '@/assets/images/services/tenant-requirement-icon.png';
import TenderDocumentsIcon from '@/assets/images/services/tender-documents-icon.png';
import { CardGrid } from '@/layouts/card-grid';
import { CenteredLayout } from '@/layouts/centered-content';
import { FluidLayout } from '@/layouts/fluid-content';
import { Nav } from '@/layouts/nav';
import { ServiceCard } from '@/organisms/service-card';
import { Route } from '@/routes';

type ServiceData = {
  id: number;
  serviceName?: ServicePermissionName;
  active?: boolean;
  icon: string;
  tooltip?: string;
  title: string;
  newServiceRoute: string;
  serviceRoute: string;
  serviceId: ServiceId | LayoutType | CeilingType; // StructuralType;
};

export const ServiceListPage: FC = () => {
  const projectId = useProjectId();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { project, isLoading } = useProject(projectId);
  const { user } = useMyUser();

  const { organization } = useOrganization(user.organizationId || '');

  const generateServiceData = useCallback(
    (order: number[], startId: number): ServiceData[] => {
      const services = [
        {
          serviceName: ServicePermissionName.USE_DUE_DILIGENCE_SERVICE,
          active: project?.services?.includes(ServiceName.DUE_DILIGENCE),
          icon: DueDiligenceIcon,
          tooltip: t(`service-tooltips.due-diligence`),
          title: t(`services.due-diligence`),
          newServiceRoute: `${Route.DUE_DILIGENCE}/${Route.CREATE}`,
          serviceRoute: Route.DUE_DILIGENCE,
          serviceId: ServiceId.DUE_DILIGENCE,
        },
        {
          serviceName: ServicePermissionName.USE_TENDER_DOCUMENTS_SERVICE,
          active: project?.services?.includes(ServiceName.TENDER_DOCUMENTS),
          icon: TenderDocumentsIcon,
          tooltip: t(`service-tooltips.tender-documents`),
          title: t(`services.tender-documents`),
          newServiceRoute: `${Route.TENDER_DOCUMENTS}/${Route.CREATE}`,
          serviceRoute: Route.TENDER_DOCUMENTS,
          serviceId: ServiceId.TENDER_DOCUMENTS,
        },
        {
          serviceName: ServicePermissionName.USE_OFFER_SERVICE,
          active: project?.services?.includes(ServiceName.OFFER),
          icon: OfferIcon,
          tooltip: t(`service-tooltips.offer`),
          title: t(`services.offer`),
          newServiceRoute: `${Route.OFFER}/${Route.CREATE}`,
          serviceRoute: Route.OFFER,
          serviceId: ServiceId.OFFER,
        },
        {
          serviceName: ServicePermissionName.USE_TENANT_REQ_SERVICE,
          active: project?.services?.includes(ServiceName.TENANT_REQUIREMENTS),
          icon: TenantRequirementIcon,
          tooltip: t(`service-tooltips.tenant-requirements`),
          title: t(`services.tenant-requirements`),
          newServiceRoute: `${Route.TENANT_REQUIREMENTS}/${Route.CREATE}`,
          serviceRoute: Route.TENANT_REQUIREMENTS,
          serviceId: ServiceId.TENANT_REQUIREMENTS,
        },
        {
          serviceName: ServicePermissionName.USE_MANAGE_OPERATE_SERVICE,
          active: project?.services?.includes(ServiceName.MANAGEMENT_OPERATIONS_MAINTENANCE),
          icon: OperationsManagementIcon,
          tooltip: t(`service-tooltips.management-operations-maintenance`),
          title: t(`services.management-operations-maintenance`),
          newServiceRoute: `${Route.MANAGEMENT_OPERATIONS_MAINTENANCE}/${Route.CREATE}`,
          serviceRoute: Route.MANAGEMENT_OPERATIONS_MAINTENANCE,
          serviceId: ServiceId.MANAGEMENT_OPERATIONS_MAINTENANCE,
        },
        {
          serviceName: ServicePermissionName.USE_CEILING_PLAN_SERVICE,
          active: project?.services?.includes(ServiceName.CEILING_PLAN),
          icon: CeilingPlanIcon,
          tooltip: t(`service-tooltips.ceiling-plan`),
          title: t(`services.ceiling-plan`),
          newServiceRoute: `${Route.CEILING_PLAN}/${Route.CREATE}`,
          serviceRoute: Route.CEILING_PLAN,
          serviceId: LayoutType.CEILING_PLAN,
        },
        {
          serviceName: ServicePermissionName.USE_PLANT_ROOM_SERVICE,
          active: project?.services?.includes(ServiceName.PLANT_ROOM),
          icon: PlantRoomIcon,
          tooltip: t(`service-tooltips.plant-room`),
          title: t(`services.plant-room`),
          newServiceRoute: `${Route.PLANT_ROOM}/${Route.CREATE}`,
          serviceRoute: Route.PLANT_ROOM,
          serviceId: LayoutType.PLANT_ROOM,
        },
        {
          serviceName: ServicePermissionName.USE_FLOOR_PLAN_SERVICE,
          active: project?.services?.includes(ServiceName.FLOOR_PLAN),
          icon: FloorPlanIcon,
          tooltip: t(`service-tooltips.floor-plan`),
          title: t(`services.floor-plan`),
          newServiceRoute: `${Route.FLOOR_PLAN}/${Route.CREATE}`,
          serviceRoute: Route.FLOOR_PLAN,
          serviceId: LayoutType.FLOOR_PLAN,
        },
        {
          serviceName: ServicePermissionName.USE_SPACE_ANALYSIS_SERVICE,
          active: project?.services?.includes(ServiceName.SPACE_ANALYSIS),
          icon: SpaceIcon,
          tooltip: t(`service-tooltips.space-analysis`),
          title: t(`services.space-analysis`),
          newServiceRoute: `${Route.SPACE_ANALYSIS}/${Route.CREATE}`,
          serviceRoute: Route.SPACE_ANALYSIS,
          serviceId: LayoutType.SPACE_ANALYSIS,
        },
        {
          serviceName: ServicePermissionName.USE_PARKING_SERVICE,
          active: project?.services?.includes(ServiceName.PARKING),
          icon: ParkingIcon,
          tooltip: t(`service-tooltips.parking`),
          title: t(`services.parking`),
          newServiceRoute: `${Route.PARKING}/${Route.CREATE}`,
          serviceRoute: Route.PARKING,
          serviceId: LayoutType.PARKING,
        },
        {
          serviceName: ServicePermissionName.USE_REPORTS_SERVICE,
          active: project?.services?.includes(ServiceName.REPORTS),
          icon: ReportsIcon,
          tooltip: t(`service-tooltips.reports`),
          title: t(`services.reports`),
          newServiceRoute: `${Route.REPORTS}/${Route.CREATE}`,
          serviceRoute: Route.REPORTS,
          serviceId: LayoutType.REPORTS,
        },
        // {
        //   serviceName: ServicePermissionName.USE_STRUCTURAL_SERVICE,
        //   active: project?.services?.includes(ServiceName.STRUCTURAL),
        //   icon: StructuralIcon,
        //   tooltip: t(`service-tooltips.structural`),
        //   title: t(`services.structural`),
        //   newServiceRoute: `${Route.STRUCTURAL}/${Route.CREATE}`,
        //   serviceRoute: Route.STRUCTURAL,
        //   serviceId: StructuralType.STRUCTURAL,
        // },
        // Ceiling service is only available to superusers temporarily
        ...(user.isSuperuser
          ? [
              {
                serviceName: ServicePermissionName.USE_CEILING_GRID_SERVICE,
                active: project?.services?.includes(ServiceName.CEILING_GRID),
                icon: FloorPlanIcon,
                tooltip: t(`service-tooltips.ceiling`),
                title: t(`services.ceiling-grid`),
                newServiceRoute: `${Route.CEILING_GRID}/${Route.CREATE}`,
                serviceRoute: Route.CEILING_GRID,
                serviceId: CeilingType.CEILING_GRID,
              },
            ]
          : []),
      ];

      return order.map((index, i) => ({
        id: startId + i,
        ...services[index],
      }));
    },
    [project?.services, t, user.isSuperuser],
  );

  const developerOrder = useMemo(
    () =>
      generateServiceData(
        user.isSuperuser
          ? [0, 8, 7, 9, 5, 10, 6, 1, 2, 3, 4, 11]
          : [0, 8, 7, 9, 5, 10, 6, 1, 2, 3, 4],
        1,
      ),
    [generateServiceData, user.isSuperuser],
  );

  // due diligence is irrelevant for contractors
  const contractorOrder = useMemo(
    () =>
      generateServiceData(
        user.isSuperuser ? [1, 2, 8, 7, 9, 5, 10, 6, 3, 4, 11] : [1, 2, 8, 7, 9, 5, 10, 6, 3, 4],
        1,
      ),
    [generateServiceData, user.isSuperuser],
  );

  const mapServicesToServiceCards = (listOfServices: ServiceData[]) => {
    return listOfServices.map((service) => {
      return (
        <React.Fragment key={service.id}>
          <ServiceCard
            id={service.serviceName}
            title={service.title}
            icon={service.icon}
            active={service.active}
            tooltip={service.tooltip}
            serviceRoute={service.serviceRoute}
            serviceId={service.serviceId}
            projectId={projectId}
            newServiceRoute={service.newServiceRoute}
          />
        </React.Fragment>
      );
    });
  };

  const filteredServices: ServiceData[] = useMemo(() => {
    let services;
    if (
      organization?.type === OrganizationType.CONTRACTOR ||
      organization?.type === OrganizationType.SUBCONTRACTOR
    ) {
      services = contractorOrder;
    } else {
      services = developerOrder;
    }
    if (user.isSuperuser || user.isOrgAdmin) {
      return services;
    }
    const currentProjectAccess = user.projectAccess.find(
      (access: ProjectAccess) => access.id === projectId,
    );
    if (currentProjectAccess) {
      if (currentProjectAccess.role === ProjectRole.ADMIN) {
        return services;
      }
      if (currentProjectAccess.role === ProjectRole.USER) {
        const availableServices: ServiceData[] = [];
        availableServices.push(
          ...services.filter(
            (service) =>
              service.serviceName && currentProjectAccess.services.includes(service.serviceName),
          ),
        );
        return availableServices;
      }
    }
    return [];
  }, [
    projectId,
    user.isOrgAdmin,
    user.isSuperuser,
    user.projectAccess,
    contractorOrder,
    developerOrder,
    organization?.type,
  ]);

  const hasActiveServices = filteredServices.filter((service) => service.active).length !== 0;
  const hasNoActiveServices = filteredServices.filter((service) => !service.active).length === 0;

  const onBackButtonClick = () => {
    navigate(`/${Route.PROJECTS}`);
  };
  return (
    <FluidLayout>
      <Nav handleClick={onBackButtonClick} />
      {isLoading ? (
        <LoadingArea title={t('service-list.loadingtitle')} className="pt-24" />
      ) : (
        <>
          <CenteredLayout>
            <div className="flex flex-col lg:flex-row w-full px-14 pt-8">
              <span className="text-[2rem] font-semibold capitalize text-day-neutral-dark">
                {project?.name}
              </span>
            </div>
            {hasActiveServices && (
              <div className="px-14 pb-8">
                <div className="text-base py-4 text-day-neutral-dark font-semibold">
                  {t('service-list.your_services')}
                </div>
                <CardGrid>
                  {mapServicesToServiceCards(filteredServices.filter((service) => service.active))}
                </CardGrid>
              </div>
            )}
          </CenteredLayout>
          {!hasNoActiveServices && (
            <div className="bg-day-light-3 flex-1">
              <CenteredLayout>
                <div className="px-14 pt-8 pb-8">
                  <div className="text-base py-4 text-day-neutral-dark font-semibold">
                    {t('service-list.add_service')}
                  </div>
                  <CardGrid>
                    {mapServicesToServiceCards(
                      filteredServices.filter((service) => !service.active),
                    )}
                  </CardGrid>
                </div>
              </CenteredLayout>
            </div>
          )}
        </>
      )}
    </FluidLayout>
  );
};
