import { mapToCamelCase, mapToSnakeCase } from '@consigli/utils';
import { z } from 'zod';
import { documentPackageSchema } from './document-package';
import { languageSchema } from './language';
import { getPaginationResponseSchema } from './pagination';
import { ProcessorName } from './processor-name';
import { projectSchema } from './project';
export var ActionStatus;
(function (ActionStatus) {
    ActionStatus[ActionStatus["NOT_RELEVANT"] = 1] = "NOT_RELEVANT";
    ActionStatus[ActionStatus["UNADDRESSED"] = 2] = "UNADDRESSED";
    ActionStatus[ActionStatus["WAITING"] = 3] = "WAITING";
    ActionStatus[ActionStatus["PROCESSED"] = 4] = "PROCESSED";
    ActionStatus[ActionStatus["DELETED"] = 5] = "DELETED";
})(ActionStatus || (ActionStatus = {}));
export var ConflictCategory;
(function (ConflictCategory) {
    ConflictCategory["RISK"] = "RISK";
    ConflictCategory["INSIGHT"] = "INSIGHT";
    ConflictCategory["PROPERTY"] = "PROPERTY";
    ConflictCategory["COMPANY"] = "COMPANY";
    ConflictCategory["RENTAL_AGREEMENT"] = "RENTAL_AGREEMENT";
    ConflictCategory["MISSING_DOCUMENTATION"] = "MISSING_DOCUMENTATION";
})(ConflictCategory || (ConflictCategory = {}));
export const actionStatusSchema = z.nativeEnum(ActionStatus);
export const findingSchema = z
    .object({
    id: z.number().int(),
    project_id: projectSchema.innerType().shape.id,
    document_package_id: documentPackageSchema.innerType().shape.id,
    blob_id: z.string().uuid().nullish(),
    file_name: z.string().nullish(),
    content: z.string().nullish(),
    page_number: z.number().int().nullish(),
    ref_document_package_id: z.string().uuid().nullish(),
    ref_blob_id: z.string().uuid().nullish(),
    ref_file_name: z.string().nullish(),
    ref_content: z.string().nullish(),
    ref_page_number: z.number().int().nullish(),
    action: actionStatusSchema,
    action_name: z.string(),
    processor_name: z.nativeEnum(ProcessorName),
    message_template: z.string(),
    message_context: z.record(z.string()),
    message_context_en: z.record(z.string()),
    type_of_conflict: z.number().int(),
    type_of_conflict_name: z.string(),
    type_of_conflict_category: z.string(),
    x0: z.number().nullish(),
    y0: z.number().nullish(),
    x1: z.number().nullish(),
    y1: z.number().nullish(),
    ref_x0: z.number().nullish(),
    ref_y0: z.number().nullish(),
    ref_x1: z.number().nullish(),
    ref_y1: z.number().nullish(),
    file_url: z.string().nullable(),
    comment_count: z.number().int(),
})
    .strict()
    .transform(mapToCamelCase);
export const createFindingSchema = findingSchema
    .innerType()
    .omit({
    id: true,
    project_id: true,
    document_package_id: true,
    file_name: true,
    ref_file_name: true,
    type_of_conflict_name: true,
    type_of_conflict_category: true,
    action: true,
    action_name: true,
    comment_count: true,
    file_url: true,
})
    .extend({
    conflict_message: z.string(),
})
    .strict()
    .transform(mapToSnakeCase);
export const createFindingResponseSchema = findingSchema.transform(mapToCamelCase);
export const getFindingsRequestSchema = z
    .object({
    project_id: z.number().int(),
    package_id: z.string().uuid(),
    page: z.union([z.number().int(), z.literal('all')]),
    page_size: z.number().int().nullish(),
    action_type: z.number().int().nullish(),
    conflict_type: z.number().int().nullish(),
    conflict_category: z.string().nullish(),
    blob_ids: z.array(z.string()).nullish(),
    search: z.string().nullish(),
    language: languageSchema.nullish(),
    processor_name: z.nativeEnum(ProcessorName).nullish(),
    commenters: z.array(z.string()).nullish(),
})
    .strict()
    .transform(mapToCamelCase);
export const getFindingsResponseSchema = getPaginationResponseSchema(findingSchema);
export const getFindingRequestSchema = z
    .object({
    project_id: z.number().int(),
    package_id: z.string().uuid(),
    finding_id: z.number().int(),
})
    .strict()
    .transform(mapToCamelCase);
export const updateFindingResponseSchema = findingSchema;
export const updateBatchFindingsResponseSchema = z.array(findingSchema).transform(mapToCamelCase);
export const getFindingsUniqueBlobsRequestSchema = z
    .object({
    project_id: z.number().int(),
    package_id: z.string().uuid(),
    action_type: z.number().int().nullish(),
    conflict_type: z.number().int().nullish(),
    conflict_category: z.string().nullish(),
    commenters: z.array(z.string()).nullish(),
})
    .strict()
    .transform(mapToCamelCase);
export const getFindingsUniqueBlobsResponseSchema = z.array(z
    .object({
    id: z.string(),
    name: z.string(),
})
    .strict()
    .transform(mapToCamelCase));
export const getFindingsConflictTypeCountRequestSchema = z
    .object({
    project_id: z.number().int(),
    package_id: z.string().uuid(),
    blob_ids: z.array(z.string()).nullish(),
    commenters: z.array(z.string()).nullish(),
})
    .strict()
    .transform(mapToCamelCase);
export const getFindingsConflictTypeCountResponseSchema = z.record(z
    .object({
    count: z.number().int(),
    subcounts: z.record(z.number().int()),
})
    .strict()
    .transform(mapToCamelCase));
export const getFindingsActionCountRequestSchema = z
    .object({
    project_id: z.number().int(),
    package_id: z.string().uuid(),
    blob_ids: z.array(z.string()).nullish(),
    conflict_type: z.number().int().nullish(),
    conflict_category: z.string().nullish(),
    commenters: z.array(z.string()).nullish(),
})
    .strict()
    .transform(mapToCamelCase);
export const getFindingsActionCountResponseSchema = z
    .record(z.number().int())
    .transform(mapToCamelCase);
export const getFindingsCommentersRequestSchema = z
    .object({
    project_id: z.number().int(),
    package_id: z.string().uuid(),
    blob_ids: z.array(z.string()).nullish(),
    action_type: z.number().int().nullish(),
    conflict_type: z.number().int().nullish(),
    conflict_category: z.string().nullish(),
})
    .strict()
    .transform(mapToCamelCase);
export const findingCommenterSchema = z
    .object({
    id: z.string(),
    name: z.string(),
})
    .strict()
    .transform(mapToCamelCase);
export const getFindingsCommentersResponseSchema = z.array(findingCommenterSchema);
