import {
  useProjectId,
  useLayoutId,
  useServiceName,
  useGetLayoutOptimizationQuery,
  useGetLayoutOptimizationResultsQuery,
} from '@consigli/hooks';
import { useMemo } from 'react';
import { Outlet } from 'react-router';

import { Breadcrumb } from '@/components/layouts/breadcrumb/breadcrumb';
import { Route } from '@/routes';

export const EngineeringDetailWrapper = () => {
  const projectId = useProjectId();
  const layoutId = useLayoutId();
  const serviceName = useServiceName();

  const { data: layout } = useGetLayoutOptimizationQuery({ projectId, layoutId });
  const { data: layoutResults } = useGetLayoutOptimizationResultsQuery({
    projectId,
    layoutId,
    page: 'all',
  });

  const hasResults = useMemo(() => {
    return layoutResults ? layoutResults.results.length > 0 : undefined;
  }, [layoutResults]);

  const navRoute = useMemo(() => {
    return `/${Route.PROJECTS}/${projectId}/${Route.SERVICES}/${serviceName}/${Route.PACKAGES}/${layoutId}/${hasResults ? Route.RESULTS : Route.INPUT}`;
  }, [projectId, serviceName, layoutId, hasResults]);

  return (
    <>
      <Breadcrumb label={layout?.name || ''} to={navRoute} />
      <Outlet />
    </>
  );
};
