import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';

export const PageNotFound: FC = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col justify-center items-center h-screen text-center">
      <h1 className="text-4xl font-bold mb-4">{t('page-not-found.title')}</h1>
      <p className="mb-4">{t('page-not-found.subtitle')}</p>
      <Link to="../" className="text-blue-500 hover:underline">
        {t('page-not-found.go-home')}
      </Link>
    </div>
  );
};
