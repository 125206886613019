import { DocumentType } from '@consigli/types';
import { saveBlob } from '@consigli/utils';
import JSZip from 'jszip';
import { useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
const ZIP_BATCH_SIZE = 100;
export const useDownloadFilesAsZip = (zipName, translation = true, useNS3451Path = false) => {
    const { t } = useTranslation();
    const [isDownloading, setIsDownloading] = useState(false);
    const abortControllerRef = useRef(null);
    const getNS3451Path = useCallback((file) => {
        if (file.type === DocumentType.BLOB) {
            if (file.ns3451.length === 0)
                return `${t('ns3451.UNCATEGORIZED')}/${file.name}`;
            const categories = file.ns3451[0].split('NS3451_')[1];
            let path = '';
            for (const index of categories.split('').keys()) {
                const subcategory = categories.substring(0, index + 1);
                path += `NS3451_${t('ns3451.NS3451_' + subcategory)}/`;
            }
            path += file.name;
            return path;
        }
        return '';
    }, [t]);
    const downloadFileZip = useCallback(async (documents) => {
        setIsDownloading(true);
        toast.info(translation
            ? t('document-list.download-begin', { n: documents.length })
            : `Downloading ${documents.length} files...`);
        abortControllerRef.current = new AbortController();
        const { signal } = abortControllerRef.current;
        try {
            const zip = new JSZip();
            const processedDocuments = documents.map((doc) => ({
                ...doc,
                name: useNS3451Path ? getNS3451Path(doc) : doc.name,
            }));
            const urlChunks = [];
            for (let i = 0; i < processedDocuments.length; i += ZIP_BATCH_SIZE) {
                urlChunks.push(processedDocuments.slice(i, i + ZIP_BATCH_SIZE));
            }
            for (const chunk of urlChunks) {
                await Promise.all(chunk.map(async (doc) => {
                    try {
                        if (!doc.fileUrl) {
                            return;
                        }
                        const response = await fetch(doc.fileUrl, { signal });
                        const blob = await response.blob();
                        zip.file(doc.name, blob);
                    }
                    catch (error) {
                        console.error(`Failed to download document: ${doc.name}`, error);
                    }
                }));
            }
            const zipBlob = await zip.generateAsync({ type: 'blob' });
            saveBlob(zipName + '.zip', zipBlob);
        }
        catch (error) {
            if (error.name === 'AbortError') {
                toast.info(translation ? t('document-list.download-aborted') : 'Download aborted');
            }
            else {
                toast.error(translation
                    ? t('document-list.download-failed')
                    : `Failed to download files. Error: ${error}`);
            }
        }
        finally {
            setIsDownloading(false);
        }
    }, [getNS3451Path, t, translation, useNS3451Path, zipName]);
    const abortDownload = useCallback(() => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
    }, []);
    return {
        isDownloading,
        downloadFileZip,
        abortDownload,
    };
};
