import { useProjectId, useProject } from '@consigli/hooks';
import { FC } from 'react';
import { Outlet } from 'react-router';

import { Breadcrumb } from '@/components/layouts/breadcrumb/breadcrumb';
import { Route } from '@/routes';

export const ServiceListWrapperPage: FC = () => {
  const projectId = useProjectId();
  const { project } = useProject(projectId);
  return (
    <>
      <Breadcrumb
        label={project?.name ?? ''}
        to={`/${Route.PROJECTS}/${projectId}/${Route.SERVICES}`}
      />
      <Outlet />
    </>
  );
};
