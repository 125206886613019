import { LoadingArea } from '@consigli/facade';
import { Document, DocumentType } from '@consigli/types';
import clsx from 'clsx';
import { FC } from 'react';

import { PDFViewer } from '@/molecules/pdf-viewer/pdf-viewer';

interface SinglePdfViewProps {
  document: Document;
  isPreview: boolean;
}

export const SinglePdfViewer: FC<SinglePdfViewProps> = ({ document, isPreview }) => {
  if (document.fileUrl === undefined) {
    return <LoadingArea />;
  }
  // Document is a finding
  if (document.type === DocumentType.FINDING && document.fileUrl) {
    return (
      <div className={clsx(isPreview ? 'w-11/12 mb-1' : 'w-5/6 mb-6')}>
        <div className="text-white text-md text-center pt-3 pl-5 truncate">{document.name}</div>
        <PDFViewer
          fileUrl={document.fileUrl}
          documentName={document.name}
          initialPageNumber={document.pageNumber}
          findingCoordinates={document.findingCoordinates}
          searchTerm={document.content}
          searchFallbackPage={document.pageNumber ?? undefined}
          isPreview={isPreview}
        />
      </div>
    );
  }
  // Document is a Blob or ParsedData
  if (document.type === DocumentType.BLOB && document.fileUrl) {
    return (
      <div className={clsx(isPreview ? 'w-11/12 mb-1' : 'w-5/6 mb-6')}>
        <div className="text-white text-md text-center pt-3 pl-5 truncate">{document.name}</div>
        <PDFViewer fileUrl={document.fileUrl} documentName={document.name} isPreview={isPreview} />
      </div>
    );
  }
  // Document is a File
  if (document.type === DocumentType.FILE && document.fileUrl) {
    return (
      <div className={clsx(isPreview ? 'w-11/12 mb-1' : 'w-5/6 mb-6')}>
        <div className="text-white text-md text-center pt-3 pl-5 truncate">{document.name}</div>
        <PDFViewer fileUrl={document.fileUrl} documentName={document.name} isPreview={isPreview} />
      </div>
    );
  }
};
