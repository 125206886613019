import { mapToCamelCase } from '@consigli/utils';
import { z } from 'zod';
export var FileUploadStatus;
(function (FileUploadStatus) {
    FileUploadStatus["PENDING"] = "PENDING";
    FileUploadStatus["UPLOADING"] = "UPLOADING";
    FileUploadStatus["UPLOADED"] = "UPLOADED";
})(FileUploadStatus || (FileUploadStatus = {}));
export const fileSchema = z
    .object({
    id: z.string().uuid(),
    project_id: z.number().int(),
    upload_status: z.nativeEnum(FileUploadStatus),
    url: z.string().min(1),
    filename: z.string(),
    filename_extension: z.string(),
    original_filename: z.string(),
    mime_type: z.string().optional(),
    size_bytes: z.number().int().optional(),
    md5: z.string().nullable(),
    uploaded_by: z.string().uuid(),
    created_at: z.string().datetime(),
    file_url: z.string(),
})
    .strict()
    .transform(mapToCamelCase);
export const fileResponseSchema = fileSchema;
export const fileUploadUrlResponseSchema = z.object({
    uploadUrl: z.string(),
});
export const fileReadUrlResponseSchema = z
    .object({
    read_url: z.string(),
})
    .strict()
    .transform(mapToCamelCase);
export const updateFileRequestSchema = z
    .object({
    uploadStatus: z.nativeEnum(FileUploadStatus).optional(),
    mimeType: z.string().optional(),
    sizeBytes: z.number().int().optional(),
    md5: z.string().optional(),
})
    .strict()
    .transform(mapToCamelCase);
export const createFileRequestSchema = z
    .object({
    uploadStatus: z.nativeEnum(FileUploadStatus).optional(),
    originalFilename: z.string(),
    mimeType: z.string().optional(),
    sizeBytes: z.number().int().optional(),
})
    .strict()
    .transform(mapToCamelCase);
export const zipableFileSchema = z
    .object({
    original_filename: z.string(),
    read_url: z.string(),
})
    .strict()
    .transform(mapToCamelCase);
