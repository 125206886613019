import { FC } from 'react';
import { Link } from 'react-router';

import { DashboardCard } from './card';

type DashboardCardNavProps = {
  navigateTo: string;
  title: string;
  body: number | string;
  className?: string;
};

export const DashboardCardNav: FC<DashboardCardNavProps> = ({
  navigateTo,
  title,
  body,
  className,
}) => (
  <Link to={navigateTo ? navigateTo : ''} relative="path" className="block w-full">
    <DashboardCard title={title} body={body} className={className} />
  </Link>
);
