import { useNavigate, useLocation } from 'react-router';

import { useFindingsContext } from './findings-context';

export const useReset = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setScrollPosition, setPage } = useFindingsContext();

  const reset = (page: number) => {
    setScrollPosition(0);
    setPage(page);
    const currentUrlParams = new URLSearchParams(location.search);
    currentUrlParams.set('page', page.toString());
    navigate(location.pathname + '?' + currentUrlParams.toString());
  };

  return { reset };
};
