import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { getSavedPath, getTokenFromCode, setSessionInStorage } from './oauth';
import { useAuthContext } from './react-context';
const useQueryParam = (param) => {
    const { search } = useLocation();
    return useMemo(() => {
        const params = new URLSearchParams(search);
        return params.get(param);
    }, [search, param]);
};
/** Auth will be the callback from OAuth. Here we'll retrieve the token and store it */
export const Auth = ({ children, destination, authConfig }) => {
    const code = useQueryParam('code');
    const navigate = useNavigate();
    const { session, setSession } = useAuthContext();
    const handleTokens = useCallback((session) => {
        setSession(session);
        /** Store auth data in session storage */
        setSessionInStorage(session);
        const savedPath = getSavedPath();
        if (savedPath) {
            navigate(savedPath);
        }
        else {
            navigate(destination);
        }
    }, [destination, navigate, setSession]);
    useEffect(() => {
        if (!code) {
            return;
        }
        /** @todo handle errors */
        getTokenFromCode(authConfig, code).then(handleTokens);
    }, [authConfig, code, handleTokens]);
    return session ? _jsx(_Fragment, { children: children }) : null;
};
