import { Checkbox, DEFAULT_PAGE_SIZE, Pagination, Position } from '@consigli/facade';
import { useProjectId, useProject, useDownloadFilesAsZip } from '@consigli/hooks';
import { Document, DocumentsType } from '@consigli/types';
import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { useViewerContext, ViewerMode } from '@/contexts/use-viewer-context';
import { BlobFilter } from '@/util/types';

import { AcceptSuggestions } from './accept-suggestion';
import { DocumentList } from './document-list';
import { DocumentsHeader } from './documents-header';
import { DocumentsPopup } from './documents-popup';
import { DownloadSelected } from './download-selected';
import { MultipleSelectionDialog } from '../../atoms/multiple-selection-dialog';
import { DocumentPreviewContainer } from '../document-viewer/document-preview';
import { DocumentFullscreenContainer } from '../document-viewer/fullscreen/document/container';

type DocumentsProps = {
  files: Document[];
  headerTitle: string;
  tabsComponent?: FC<{ onClick: () => void }>;
  paginatedCount: number;
  totalCount: number;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  searchText?: string;
  isFetching: boolean;
  fetchAllFiles: () => Promise<Document[]>;
  isFetchingAllFiles: boolean;
  selectedTab?: BlobFilter;
  packageId: string;
  children?: ReactNode;
};

export const Documents: FC<DocumentsProps> = ({
  files,
  headerTitle,
  tabsComponent: TabsComponent,
  paginatedCount,
  totalCount,
  page,
  setPage,
  searchText,
  isFetching,
  fetchAllFiles,
  isFetchingAllFiles,
  selectedTab,
  packageId,
  children,
}) => {
  const { t } = useTranslation();
  const projectId = useProjectId();
  const { project } = useProject(projectId);
  const [pageRecords, setPageRecords] = useState<DocumentsType[]>([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [showDownloadPopup, setShowDownloadPopup] = useState(false);
  const { mode, setMode } = useViewerContext();

  const resetSelectAll = useCallback(() => {
    setSelectAllChecked(false);
    setPageRecords((prevData: DocumentsType[]) =>
      prevData.map((item: DocumentsType) => ({ ...item, checked: false })),
    );
    setShowDownloadPopup(false);
  }, []);

  const { downloadFileZip: downloadAll, isDownloading: isDownloadingAll } = useDownloadFilesAsZip(
    project?.name ?? 'files',
  );

  const selectedFiles = useMemo(
    () => pageRecords?.filter((file: DocumentsType) => file.checked),
    [pageRecords],
  );

  const {
    downloadFileZip: downloadSelected,
    isDownloading: isDownloadingSelected,
    abortDownload,
  } = useDownloadFilesAsZip(project?.name ?? 'files');

  const isNameSuggestionTab =
    selectedTab &&
    selectedTab !== BlobFilter.PARSED &&
    selectedTab !== BlobFilter.UNPARSED &&
    selectedTab !== BlobFilter.DUPLICATE;

  const isDownloading = useMemo(
    () => isDownloadingAll || isDownloadingSelected || isFetchingAllFiles,
    [isDownloadingAll, isDownloadingSelected, isFetchingAllFiles],
  );

  const handleSelectAllCheckboxClick = useCallback(() => {
    setSelectAllChecked(!selectAllChecked);
    setPageRecords((prevData: DocumentsType[]) =>
      prevData.map((item: DocumentsType) => item && { ...item, checked: !selectAllChecked }),
    );
  }, [selectAllChecked]);

  const onClickDownloadAll = useCallback(async () => {
    const files = await fetchAllFiles();
    downloadAll(files);
  }, [fetchAllFiles, downloadAll]);

  const onClickDownloadSelected = useCallback(async () => {
    await downloadSelected(selectedFiles);
    resetSelectAll();
    setShowDownloadPopup(false);
  }, [downloadSelected, selectedFiles, resetSelectAll]);

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage],
  );

  const handleClosePopup = useCallback(() => {
    resetSelectAll();
    abortDownload();
  }, [resetSelectAll, abortDownload]);

  useEffect(() => {
    setPageRecords(files as DocumentsType[]);
  }, [files]);

  useEffect(() => {
    if (selectedFiles?.length > 1) setShowDownloadPopup(true);
  }, [selectedFiles?.length, pageRecords]);

  useEffect(() => {
    setSelectAllChecked(false);
  }, [page]);

  useEffect(() => {
    return () => {
      setMode(ViewerMode.Closed);
    };
  }, [setMode]);

  return (
    <>
      <div className="px-4 relative bg-day-light-3 pb-8">
        <DocumentsHeader
          totalCount={totalCount}
          isDownloading={isDownloading}
          downloadAll={onClickDownloadAll}
          title={headerTitle}
        />
        <div className="px-4">
          <div className="flex flex-col md:flex-row items-center justify-between">
            <div className="flex flex-col md:flex-row py-2">
              {TabsComponent && <TabsComponent onClick={resetSelectAll} />}
            </div>
          </div>
          <div className="ml-1 mb-2 flex w-full">
            <div className="flex-shrink-0">
              {pageRecords.length > 0 && (
                <Checkbox
                  className="whitespace-nowrap"
                  checked={selectAllChecked}
                  label={t('riskassessment.select-all')}
                  value="Select all"
                  id="documentsSelectAll"
                  labelPosition={Position.RIGHT}
                  onChange={handleSelectAllCheckboxClick}
                />
              )}
            </div>
            <div className="flex-grow">{children}</div>
          </div>
          <DocumentList
            isFetching={isFetching}
            pageRecords={pageRecords}
            setPageRecords={setPageRecords}
            selectedTab={selectedTab}
            searchText={searchText}
          />
          <div className="pt-4 flex flex-col md:flex-row justify-center items-center gap-8 text-day-neutral-dark">
            <Pagination
              currentPage={page}
              totalCount={paginatedCount}
              pageSize={DEFAULT_PAGE_SIZE}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
        <MultipleSelectionDialog>
          {showDownloadPopup && selectedFiles?.length > 1 && (
            <DocumentsPopup
              files={selectedFiles}
              setShowPopup={(visible) => {
                if (!visible) {
                  handleClosePopup();
                }
                setShowDownloadPopup(visible);
              }}
              onClose={handleClosePopup}
            >
              {isNameSuggestionTab ? (
                <AcceptSuggestions
                  files={selectedFiles}
                  isLoading={isDownloading}
                  packageId={packageId}
                />
              ) : (
                <DownloadSelected
                  onClickDownloadSelected={onClickDownloadSelected}
                  isLoading={isDownloading}
                />
              )}
            </DocumentsPopup>
          )}
        </MultipleSelectionDialog>
      </div>
      {mode === ViewerMode.DocumentPreview && <DocumentPreviewContainer />}
      {mode === ViewerMode.DocumentFullscreen && <DocumentFullscreenContainer />}
    </>
  );
};
