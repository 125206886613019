import { createPortal } from 'react-dom';
import { Link } from 'react-router';

import { useBreadcrumbContext } from '@/components/layouts/breadcrumb/use-breadcrumb-context';

import type { FC } from 'react';

interface BreadcrumbProps {
  label: string;
  to?: string;
}

export const Breadcrumb: FC<BreadcrumbProps> = ({ label, to = '' }) => {
  const [portalNode] = useBreadcrumbContext();
  return portalNode ? (
    <>
      {createPortal(
        <>
          <span className="first:hidden text-xs relative mt-1 text-day-neutral-subtle">
            {'\u276F'}
          </span>
          <Link
            to={to}
            title={label}
            relative="path"
            className="cursor-pointer truncate border-transparent border-b-2 hover:border-day-primary text-sm focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-light-grey transition-colors duration-300 text-day-neutral-subtle"
          >
            {label}
          </Link>
        </>,
        portalNode,
      )}
    </>
  ) : null;
};
