import { useParams } from 'react-router';
/**
 * @returns the projectId from the current route (url)
 */
export const useProjectId = () => {
    const { projectId } = useParams();
    if (projectId == null) {
        throw new Error(`useProjectId called on route without :projectId`);
    }
    return Number.parseInt(projectId);
};
