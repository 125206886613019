import { Document } from '@consigli/types';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import '@cyntler/react-doc-viewer/dist/index.css';
import clsx from 'clsx';

interface DocViewerProps {
  document: Document;
  isPreview: boolean;
}

export const DocumentViewer = ({ document, isPreview }: DocViewerProps) => {
  const documentWithUri = {
    ...document,
    uri: document.fileUrl || '',
    fileName: document.name,
  };
  return (
    <div className={clsx(isPreview ? 'w-11/12 mb-1 mt-8' : 'w-5/6')}>
      <DocViewer
        documents={[documentWithUri]}
        pluginRenderers={DocViewerRenderers}
        className="h-full"
      />
    </div>
  );
};
