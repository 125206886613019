import { useBreakpoint } from '@consigli/hooks';
import clsx from 'clsx';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FaFileLines,
  FaHouse,
  FaFolderOpen,
  FaPencil,
  FaFileArrowDown,
  FaFileArrowUp,
} from 'react-icons/fa6';
import { IoChatbubbles } from 'react-icons/io5';
import { Link, useLocation } from 'react-router';

interface WorkspaceTabsItemProps {
  name: string;
  href: string;
}

function mapTabNameToIcon(tabName: string) {
  const iconMap: Record<string, JSX.Element | null> = {
    'servicetabs.overview': <FaHouse />,
    'servicetabs.riskassessment': <FaPencil />,
    'servicetabs.documents': <FaFileLines />,
    'servicetabs.propchat': <IoChatbubbles />,
    'servicetabs.folders': <FaFolderOpen />,
    'optimization-tabs.input': <FaFileArrowUp />,
    'optimization-tabs.results': <FaFileArrowDown />,
    'structural-tabs.input': <FaFileArrowUp />,
    'structural-tabs.results': <FaFileArrowDown />,
  };

  return iconMap[tabName] || null; // Return null for unknown tabName
}

export const WorkspaceTabsItem: FC<WorkspaceTabsItemProps> = ({ name, href }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const isActive = location.pathname.startsWith(href);
  const smallWindow = useBreakpoint(1024);

  return (
    <Link
      className={`${clsx(
        isActive &&
          'text-night-neutral-dark border bg-brand-default hover:bg-brand-default border-day-secondary transition-all duration-1000 ease-in-out',
      )} py-2 px-6 rounded flex items-center justify-center text-center hover:bg-brand-default border-transparent border hover:border h-10 text-day-neutral-dark hover:text-night-neutral-dark`}
      to={href}
    >
      <div className="w-full whitespace-nowrap text-lg">
        {smallWindow ? t(name) : mapTabNameToIcon(name)}
      </div>
    </Link>
  );
};
