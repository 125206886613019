import { Auth, AuthGuard } from '@consigli/oauth';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  Routes,
  useParams,
} from 'react-router';

import { UserGuard } from '@/layouts/user-guard';
import { DocumentServicePackageDetailWrapper } from '@/pages/document-service-package-detail-wrapper';
import { FoldersPage } from '@/pages/folders/folders-page';
import { ProjectListPage } from '@/pages/project-list';
import { RootPage } from '@/pages/root';
import { ServiceListPage } from '@/pages/service-list';
import { authConfig } from '@/util/oauth/config';

import { InviteCheck } from './components/layouts/invite-check';
import { PageNotFound } from './components/layouts/page-not-found';
import { Dashboard } from './modules/pages/dashboard';
import { DocumentListing } from './modules/pages/document-listing';
import { RiskAssessment } from './modules/pages/risk-assessment';
import { CeilingGridDetailWrapper } from './pages/ceiling-grid/detail-wrapper';
import { CeilingGridFileList } from './pages/ceiling-grid/document-file-list';
import { CeilingGridNavigationWrapper } from './pages/ceiling-grid/navigation-wrapper';
import { CeilingGridResultFileList } from './pages/ceiling-grid/result-file-list';
import { EngineeringDetailWrapper } from './pages/engineering/detail-wrapper';
import { EngineeringFileList } from './pages/engineering/document-file-list';
import { EngineeringNavigationWrapper } from './pages/engineering/navigation-wrapper';
import { EngineeringResultFileList } from './pages/engineering/result-file-list';
import { ErrorPage } from './pages/error-page';
import { InviteUser } from './pages/invite-user/invite-user';
import { InsightAddMoreDocuments } from './pages/package-creation/insight/insight-add-more-documents';
import { WizardWrapper } from './pages/package-creation/wizard-wrapper';
import { ProjectCreation } from './pages/project-creation/project-creation';
import { ProjectListWrapperPage } from './pages/project-list-wrapper';
import { PropChat } from './pages/prop-chat';
import { ServiceDetailWrapperPage } from './pages/service-detail-wrapper';
import { ServiceListWrapperPage } from './pages/service-list-wrapper';
import { StructuralDetailWrapper } from './pages/structural/detail-wrapper';
import { StructuralFileList } from './pages/structural/file-list';
import { StructuralNavigationWrapper } from './pages/structural/navigation-wrapper';
import { StructuralResultFileList } from './pages/structural/result-file-list';
import { StructuralChoicePage } from './pages/structural/structural-choice';
import { UserManagementPage } from './pages/user-management/user-management-page';
import { UserManagementWrapper } from './pages/user-management/user-management-wrapper';
import { Terms } from './pages/user-profile/terms';
import { UserProfileWrapper } from './pages/user-profile/user-profile-wrapper';
import { Route as NavRoute } from './routes';

const RouteRenderer = () => {
  const { serviceName } = useParams();
  if (
    serviceName === NavRoute.FLOOR_PLAN ||
    serviceName === NavRoute.CEILING_PLAN ||
    serviceName === NavRoute.PLANT_ROOM ||
    serviceName === NavRoute.SPACE_ANALYSIS ||
    serviceName === NavRoute.PARKING ||
    serviceName === NavRoute.REPORTS
  ) {
    return (
      <Routes>
        <Route path=":layoutId" element={<EngineeringDetailWrapper />}>
          <Route path={NavRoute.DASHBOARD} element={<EngineeringNavigationWrapper />} />
          <Route path={NavRoute.INPUT} element={<EngineeringFileList />} />
          <Route path={NavRoute.RESULTS} element={<EngineeringResultFileList />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    );
  }
  if (serviceName === NavRoute.CEILING_GRID) {
    return (
      <Routes>
        <Route path=":ceilingGridId" element={<CeilingGridDetailWrapper />}>
          <Route path={NavRoute.DASHBOARD} element={<CeilingGridNavigationWrapper />} />
          <Route path={NavRoute.INPUT} element={<CeilingGridFileList />} />
          <Route path={NavRoute.RESULTS} element={<CeilingGridResultFileList />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    );
  }
  if (serviceName === NavRoute.STRUCTURAL) {
    return (
      <Routes>
        <Route path=":structuralId" element={<StructuralDetailWrapper />}>
          <Route path={NavRoute.DASHBOARD} element={<StructuralNavigationWrapper />} />
          <Route path={NavRoute.INPUT} element={<StructuralFileList />} />
          <Route path={NavRoute.RESULTS} element={<Outlet />}>
            <Route index element={<StructuralChoicePage />} />
            <Route path=":resultType" element={<StructuralResultFileList />} />
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path=":packageId" element={<DocumentServicePackageDetailWrapper />}>
        <Route path={NavRoute.DASHBOARD} element={<Dashboard />} />
        <Route path={NavRoute.FINDINGS} element={<RiskAssessment />} />
        <Route path={NavRoute.DOCUMENTS} element={<DocumentListing />} />
        <Route path={NavRoute.CATEGORIZATION} element={<FoldersPage />} />
        <Route path={NavRoute.PROPCHAT} element={<PropChat />} />
        <Route path={NavRoute.UPLOAD} element={<InsightAddMoreDocuments />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};

export const routes = createRoutesFromElements(
  <>
    <Route
      path="/"
      element={
        <AuthGuard authConfig={authConfig}>
          <UserGuard>
            <InviteCheck>
              <RootPage />
            </InviteCheck>
          </UserGuard>
        </AuthGuard>
      }
      errorElement={<ErrorPage />}
    >
      <Route index element={<Navigate to={`/${NavRoute.PROJECTS}`} />} />
      <Route path={`/${NavRoute.NEW_PROJECT}`} element={<ProjectCreation />} />
      <Route path={NavRoute.PROJECTS} element={<ProjectListWrapperPage />}>
        <Route index element={<ProjectListPage />} />
        <Route path={`:projectId/${NavRoute.SERVICES}`} element={<ServiceListWrapperPage />}>
          <Route index element={<ServiceListPage />} />
          <Route path=":serviceName" element={<ServiceDetailWrapperPage />}>
            <Route index element={<WizardWrapper />} />
            <Route path={NavRoute.CREATE} element={<WizardWrapper />} />
            <Route path={NavRoute.PACKAGES} element={<Outlet />}>
              <Route path="*" element={<RouteRenderer />} />
            </Route>
          </Route>
        </Route>
      </Route>
      <Route path={NavRoute.USER_MANAGEMENT} element={<UserManagementWrapper />}>
        <Route index element={<UserManagementPage />} />
        <Route path={NavRoute.INVITE_USER} element={<InviteUser />}></Route>
      </Route>
      <Route path="profile" element={<UserProfileWrapper />}></Route>
    </Route>
    <Route path={NavRoute.TERMS} element={<Terms />} />
    <Route path="/logout" element={<Navigate to={`/${NavRoute.PROJECTS}`} />} />
    <Route
      path="/auth"
      element={
        <Auth
          destination={sessionStorage.getItem('savedPath') || `/${NavRoute.ROOT}`}
          authConfig={authConfig}
        />
      }
    />
    <Route path="*" element={<PageNotFound />} />
  </>,
);

export const router = createBrowserRouter(routes);
