import { Button } from '@consigli/facade';
import { useTranslation } from 'react-i18next';
import { SlPaperPlane } from 'react-icons/sl';
import { useRouteError } from 'react-router';

import crashIcon from '@/assets/images/crash-icon.svg';

export const ErrorPage = () => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const error: unknown = useRouteError();
  let errorMessage = '';
  if (typeof error === 'object' && error !== null && 'message' in error) {
    errorMessage = (error as { message: string }).message;
  }
  const mailBody =
    t('error.mail-body-error') + '"' + errorMessage + '"' + t('error.mail-body-description');
  const encodedMailBody = encodeURIComponent(mailBody);
  const encodedMailSubject = encodeURIComponent(t('error.mail-subject'));

  const handleSendMail = () => {
    window.open(
      'mailto:customersuccess@consigli.ai?subject=' +
        encodedMailSubject +
        '&body=' +
        encodedMailBody.replace(/\n/g, '%0D%0A'),
    );
  };
  return (
    <div className="min-h-screen flex flex-col justify-center items-center">
      <img src={crashIcon} alt="crash icon" className="w-1/5 pb-10" />
      <div className="text-title">{t('error.general')}</div>
      <div className="text-primary mt-1">{t('error.send-text')}</div>
      <div className="text-tertiary text-day-neutral-subtle mt-6">{t('error.message')}</div>
      <div className="mb-2">{errorMessage}</div>
      <Button
        secondary
        onClick={handleSendMail}
        rounded
        className="text-base m-3 font-semibold"
        icon={SlPaperPlane}
      >
        {t('error.send-button')}
      </Button>
    </div>
  );
};
