import { Button, Position } from '@consigli/facade';
import { useMyUser, useGetInviteCountQuery, useGetUserCountQuery } from '@consigli/hooks';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineUserAdd } from 'react-icons/hi';
import { HiOutlineArrowLeft } from 'react-icons/hi2';
import { useNavigate } from 'react-router';

import { CenteredLayout } from '@/layouts/centered-content';
import { FluidLayout } from '@/layouts/fluid-content';
import { Route } from '@/routes';

import { UserManagementInviteList } from './user-management-invites-list';
import { UserManagementTabs } from './user-management-tabs';
import { UserManagementUserList } from './user-management-user-list';

export enum UserManagementTab {
  USERS = 'USERS',
  INVITES = 'INVITES',
}

export const UserManagementPage: FC = () => {
  const { user } = useMyUser();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data: { count: totalInviteCount } = { count: 0 } } = useGetInviteCountQuery({
    invitedByEmail: !user.isSuperuser ? user.email : '',
  });
  const { data: { count: totalUserCount } = { count: 0 } } = useGetUserCountQuery();

  const [selectedTab, setSelectedTab] = useState(UserManagementTab.USERS);

  const handleBackClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleTabClick = useCallback(
    (tab: UserManagementTab) => {
      setSelectedTab(tab);
    },
    [setSelectedTab],
  );

  return (
    <FluidLayout>
      <CenteredLayout>
        <nav className="flex justify-between w-full py-4 border-b border-day-secondary">
          <Button
            tertiary
            rounded
            className="mt-4 md:mt-0 text-base mx-7"
            icon={HiOutlineArrowLeft}
            onClick={handleBackClick}
          >
            {t('riskassessment.back')}
          </Button>
          <Button
            primary
            rounded
            className="mt-4 md:mt-0 text-base mx-7"
            icon={HiOutlineUserAdd}
            iconColor="#fff"
            iconSize={20}
            iconPosition={Position.RIGHT}
            onClick={() => navigate(`${Route.INVITE_USER}`)}
          >
            {t('user-management.invite-new-user')}
          </Button>
        </nav>
        <div className="my-6 mx-10">
          {user.organizationName && (
            <p className="text-sm text-day-neutral-subtle font-bold">{user.organizationName}</p>
          )}
          <h1 className="text-2xl font-bold mb-5">{t('user-management.title')}</h1>
          <UserManagementTabs
            handleTabClick={handleTabClick}
            selectedTab={selectedTab}
            keys={Object.values(UserManagementTab) as UserManagementTab[]}
            userCount={totalUserCount}
            inviteCount={totalInviteCount}
          />
          {selectedTab === UserManagementTab.USERS && <UserManagementUserList />}
          {selectedTab === UserManagementTab.INVITES && <UserManagementInviteList />}
        </div>
      </CenteredLayout>
    </FluidLayout>
  );
};
