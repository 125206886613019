export var ProcessorName;
(function (ProcessorName) {
    ProcessorName["BREEAM_ENERGY_CHECK"] = "breeam-energy-check";
    ProcessorName["BREEZE"] = "breeze";
    ProcessorName["BYG_DEADLINES"] = "byg-deadlines";
    ProcessorName["BYG_MANDATORY_HEADER"] = "byg-mandatory-header";
    ProcessorName["BYG_NAMES"] = "byg-names";
    ProcessorName["BYG_TIME_PERIOD_DEADLINES"] = "byg-time-period-deadlines";
    ProcessorName["CONTRACT_DEVIATION_CHECKER"] = "contract-deviation-checker";
    ProcessorName["CONTRACT_INTERNAL_DEVIATION"] = "contract-internal-deviation";
    ProcessorName["DOCUMENT_TO_PDF_CONVERTER"] = "document-to-pdf-converter";
    ProcessorName["DRAWING_CLASSIFIER"] = "drawing-classifier";
    ProcessorName["DUPLICATE_CHECKER"] = "duplicate-checker";
    ProcessorName["FDV_CLASSIFIER"] = "fdv-classifier";
    ProcessorName["FDV_MANDATORY"] = "fdv-mandatory";
    ProcessorName["FILENAME_CHECK"] = "filename-check";
    ProcessorName["FILENAME_SUGGESTION"] = "filename-suggestion";
    ProcessorName["FUNCTIONAL_DESCRIPTIONS"] = "functional-descriptions";
    ProcessorName["GPT_DOCUMENT_CLASSIFIER"] = "gpt-document-classifier";
    ProcessorName["HUMAN_INTELLIGENCE"] = "human-intelligence";
    ProcessorName["KVALIFIKASJONSKRAV_CHECKER"] = "kvalifikasjonskrav-checker";
    ProcessorName["ORG_INFO"] = "org-info";
    ProcessorName["PARSER_CONTENT_EXTRACTOR"] = "parser-content-extractor";
    ProcessorName["PARSER_OPENAI_EMBEDDINGS"] = "parser-openai-embeddings";
    ProcessorName["PRE_PARSER_OCR"] = "pre-parser-ocr";
    ProcessorName["PRODUCTS_COMPARE"] = "products-compare";
    ProcessorName["SFP"] = "sfp";
    ProcessorName["SIMILAR_HEADERS"] = "similar-headers";
    ProcessorName["ST_ANBEFALE"] = "st-anbefale";
    ProcessorName["ST_COMPARE"] = "st-compare";
    ProcessorName["ST_VALID_CHECKER"] = "st-valid-checker";
    ProcessorName["TEXT_PROCESSOR_ANBEFALE"] = "text-processor-anbefale";
    ProcessorName["TEXT_PROCESSOR_GLIST"] = "text-processor-glist";
    ProcessorName["TEXT_PROCESSOR_GLIST_DD"] = "text-processor-glist-dd";
    ProcessorName["TEXT_PROCESSOR_GLIST_FDV"] = "text-processor-glist-fdv";
    ProcessorName["TEXT_PROCESSOR_GLIST_FDV_UK"] = "text-processor-glist-fdv-uk";
    ProcessorName["TEXT_PROCESSOR_GLIST_INF"] = "text-processor-glist-inf";
    ProcessorName["TEXT_PROCESSOR_GLIST_LB_KG"] = "text-processor-glist-lb-kg";
    ProcessorName["TEXT_PROCESSOR_GLIST_UK"] = "text-processor-glist-uk";
    ProcessorName["TEXT_PROCESSOR_HIMLING"] = "text-processor-himling";
    ProcessorName["TEXT_PROCESSOR_LYDKRAV"] = "text-processor-lydkrav";
    ProcessorName["TEXT_PROCESSOR_PRICES"] = "text-processor-prices";
    ProcessorName["TEXT_PROCESSOR_SAMSPILL"] = "text-processor-samspill";
    ProcessorName["TEXT_PROCESSOR_SENTRALISERT"] = "text-processor-sentralisert";
    ProcessorName["TEXT_PROCESSOR_SUBJECTS"] = "text-processor-subjects";
    ProcessorName["TEXT_PROCESSOR_TEK17"] = "text-processor-tek17";
    ProcessorName["TEXT_PROCESSOR_VAGUE"] = "text-processor-vague";
    ProcessorName["U_VALUE_CHECKER"] = "u-value-checker";
    ProcessorName["UVALUE"] = "uvalue";
    ProcessorName["VENTILATION_SFP_CHECKER"] = "ventilation-sfp-checker";
    ProcessorName["CONTRACT_EXTERNAL_DEVIATION"] = "contract-external-deviation";
    ProcessorName["EDITABLE_DOCUMENT"] = "editable-document";
    ProcessorName["MISSING_REQUIREMENTS"] = "missing-requirements";
    ProcessorName["MISSING_STANDARDS"] = "missing-standards";
    ProcessorName["FDV_MISSING_DRAWINGS"] = "fdv-missing-drawings";
})(ProcessorName || (ProcessorName = {}));
