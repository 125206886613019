import { Button } from '@consigli/facade';
import { useConvertFindingToDocument } from '@consigli/types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { ViewerMode, useViewerContext } from '@/contexts/use-viewer-context';
import { useFindingsContext } from '@/modules/pages/risk-assessment/findings-context';

interface FindingsNavigationButtonsProps {
  mode: ViewerMode;
}

export const FindingsNavigationButtons = ({ mode }: FindingsNavigationButtonsProps) => {
  const { t } = useTranslation();
  const { setDocument, setMode } = useViewerContext();
  const { getNextFinding, getPreviousFinding, setCurrentFinding } = useFindingsContext();
  const convertFindingToDocument = useConvertFindingToDocument(t);

  const handleNavigation = (direction: 'next' | 'previous') => {
    const finding = direction === 'next' ? getNextFinding() : getPreviousFinding();
    if (finding) {
      setCurrentFinding(finding);
      const convertedDocument = convertFindingToDocument(finding);
      setDocument(convertedDocument);
      setMode(mode);
    }
  };

  return (
    <div className="flex gap-4">
      <Button
        primary
        className={clsx(
          'fc-px-3 fc-py-2',
          mode === ViewerMode.DocumentFullscreen ? 'border-none' : 'rounded',
        )}
        onClick={() => handleNavigation('previous')}
        disabled={!getPreviousFinding()}
      >
        {t('document-navigation.previous')}
      </Button>
      <Button
        primary
        className={clsx(
          'fc-px-3 fc-py-2',
          mode === ViewerMode.DocumentFullscreen ? 'border-none' : 'rounded',
        )}
        onClick={() => handleNavigation('next')}
        disabled={!getNextFinding()}
      >
        {t('document-navigation.next')}
      </Button>
    </div>
  );
};
