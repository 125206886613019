import {
  useGetStructuralEngineeringQuery,
  useGetStructuralEngineeringResultsQuery,
  useProjectId,
  useServiceName,
  useStructuralId,
} from '@consigli/hooks';
import { useMemo } from 'react';
import { Outlet } from 'react-router';

import { Breadcrumb } from '@/components/layouts/breadcrumb/breadcrumb';
import { Route } from '@/routes';

export const StructuralDetailWrapper = () => {
  const projectId = useProjectId();
  const structuralId = useStructuralId();
  const serviceName = useServiceName();

  const { data: structural } = useGetStructuralEngineeringQuery({ projectId, structuralId });
  const { data: structuralResults } = useGetStructuralEngineeringResultsQuery({
    projectId,
    structuralId,
    page: 'all',
  });

  const hasResults = useMemo(() => {
    return structuralResults ? structuralResults.results.length > 0 : undefined;
  }, [structuralResults]);

  const navRoute = useMemo(() => {
    return `/${Route.PROJECTS}/${projectId}/${Route.SERVICES}/${serviceName}/${Route.PACKAGES}/${structuralId}/${hasResults ? Route.RESULTS : Route.INPUT}`;
  }, [projectId, serviceName, structuralId, hasResults]);

  return (
    <>
      <Breadcrumb label={structural?.name || ''} to={navRoute} />
      <Outlet />
    </>
  );
};
