import { FileUpload, UseStepContext } from '@consigli/facade';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { WizardAnalyzeButton } from './wizard-analyze-button';
import { WizardBackButton } from './wizard-back-button';

interface UserUploadProps {
  localFiles: File[];
  setLocalFiles: Dispatch<SetStateAction<File[]>>;
  useStepContext: UseStepContext;
  onAnalyze: () => void;
  infoMessage?: string | JSX.Element;
}

export const UserUpload = ({
  localFiles,
  setLocalFiles,
  useStepContext,
  onAnalyze,
  infoMessage,
}: UserUploadProps) => {
  const { t } = useTranslation();
  const [showMessagePanel, setShowMessagePanel] = useState(true);

  return (
    <FileUpload
      files={localFiles}
      setFiles={setLocalFiles}
      dropAreaLabel={t('document-upload.drag-n-drop-here')}
      dropAreaClassName="mt-4 h-48 w-full"
      fileButtonLabel={t('document-upload.browse-files')}
      folderButtonLabel={t('document-upload.browse-folders')}
      heading={t('package-creation.upload-files')}
      uploadListLabel={t('document-upload.files')}
      zeroByteFilesMessage={t('document-upload.file-zero-bytes')}
      infoMessage={infoMessage}
      showMessagePanel={showMessagePanel}
      setShowMessagePanel={setShowMessagePanel}
    >
      <div className="fc-grid fc-grid-cols-2 fc-gap-4 fc-w-fit">
        <WizardBackButton context={useStepContext} />
        <WizardAnalyzeButton localFiles={localFiles} onAnalyze={onAnalyze}></WizardAnalyzeButton>
      </div>
    </FileUpload>
  );
};
