import { UseStepContext, Button, FileUpload } from '@consigli/facade';
import { useTranslation } from 'react-i18next';

import { useCompareContext } from '../compare/use-compare-packages';

interface PrimaryPackageUploadProps {
  context: UseStepContext;
}

export const PrimaryPackageUpload = ({ context }: PrimaryPackageUploadProps) => {
  const { next, previous, hasPrevious } = context();
  const { primaryFiles, setPrimaryFiles } = useCompareContext();
  const { t } = useTranslation();
  return (
    <>
      <FileUpload
        files={primaryFiles}
        setFiles={setPrimaryFiles}
        dropAreaLabel={t('document-upload.drag-n-drop-here')}
        dropAreaClassName="mt-4 h-48 w-full"
        fileButtonLabel={t('document-upload.browse-files')}
        folderButtonLabel={t('document-upload.browse-folders')}
        heading={t('package-creation.upload-files')}
        uploadListLabel={t('document-upload.files')}
        zeroByteFilesMessage={t('document-upload.file-zero-bytes')}
      >
        <div className="flex flex-row gap-4">
          <Button
            rounded
            primary
            className="w-full fc-px-3 fc-py-2 mt-2"
            onClick={() => {
              hasPrevious && previous();
            }}
            iconColor="white"
          >
            {t('package-creation.back')}
          </Button>
          <Button
            rounded
            primary
            className="w-full fc-px-3 fc-py-2 mt-2"
            onClick={() => {
              next();
            }}
            disabled={primaryFiles?.length === 0}
            iconColor="white"
          >
            {t('package-creation.next')}
          </Button>
        </div>
      </FileUpload>
    </>
  );
};
