import { DEFAULT_PAGE_SIZE } from '@consigli/facade';
import { useDebouncedSearch } from '@consigli/hooks';
import { ConflictCategory } from '@consigli/types';
import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useRef,
  useState,
  useCallback,
} from 'react';
import { useSearchParams } from 'react-router';

import { CheckableFinding } from '@/util/types';

type FindingsContext = {
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  debouncedSearchText: string;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  pageSize: number;
  showFindingIds: boolean;
  setShowFindingIds: Dispatch<SetStateAction<boolean>>;
  selectedActionTab: string;
  setSelectedActionTab: Dispatch<SetStateAction<string>>;
  selectedCommenterIds: string[];
  setSelectedCommenterIds: Dispatch<SetStateAction<string[]>>;
  selectedBlobIds: string[];
  setSelectedBlobIds: Dispatch<SetStateAction<string[]>>;
  selectedConflictCategory: ConflictCategory | undefined;
  setSelectedConflictCategory: Dispatch<SetStateAction<ConflictCategory | undefined>>;
  selectedConflictType: string | undefined;
  setSelectedConflictType: Dispatch<SetStateAction<string | undefined>>;
  scrollPosition: number;
  setScrollPosition: Dispatch<SetStateAction<number>>;
  containerRef: React.RefObject<HTMLDivElement | undefined>;
  pageRecords: CheckableFinding[];
  setPageRecords: Dispatch<SetStateAction<CheckableFinding[]>>;
  currentFinding: CheckableFinding | null;
  setCurrentFinding: Dispatch<SetStateAction<CheckableFinding | null>>;
  getNextFinding: () => CheckableFinding | null;
  getPreviousFinding: () => CheckableFinding | null;
};

export const FindingsContext = createContext<FindingsContext | null>(null);

export const useFindingsContext = () => {
  const ctx = useContext(FindingsContext);

  if (!ctx) {
    throw new Error('Missing Findings Provider in tree');
  }

  return ctx;
};

export const FindingsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [queryParams] = useSearchParams();

  const queryParamConflictCategory = queryParams.get('conflictCategory') as ConflictCategory;
  const queryParamConflictType = queryParams.get('conflictType');
  const pageNum = queryParams.get('page');

  const [selectedConflictCategory, setSelectedConflictCategory] = useState<
    ConflictCategory | undefined
  >(queryParamConflictCategory || undefined);
  const [selectedConflictType, setSelectedConflictType] = useState<string | undefined>(
    queryParamConflictType || undefined,
  );

  const [showFindingIds, setShowFindingIds] = useState<boolean>(false);
  const [selectedActionTab, setSelectedActionTab] = useState<string>('2');
  const [selectedCommenterIds, setSelectedCommenterIds] = useState<string[]>([]);
  const [selectedBlobIds, setSelectedBlobIds] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearchText = useDebouncedSearch(searchTerm, 500);
  const [page, setPage] = useState(pageNum ? Number(pageNum) : 1);
  const [scrollPosition, setScrollPosition] = useState(0);
  const containerRef = useRef<HTMLDivElement | undefined>(undefined);
  const [pageRecords, setPageRecords] = useState<CheckableFinding[]>([]);
  const [currentFinding, setCurrentFinding] = useState<CheckableFinding | null>(null);

  const getNextFinding = useCallback(() => {
    if (!currentFinding) return null;
    const currentIndex = pageRecords.findIndex((f) => f.id === currentFinding.id);
    return currentIndex < pageRecords.length - 1 ? pageRecords[currentIndex + 1] : null;
  }, [currentFinding, pageRecords]);

  const getPreviousFinding = useCallback(() => {
    if (!currentFinding) return null;
    const currentIndex = pageRecords.findIndex((f) => f.id === currentFinding.id);
    return currentIndex > 0 ? pageRecords[currentIndex - 1] : null;
  }, [currentFinding, pageRecords]);

  const pageSize = DEFAULT_PAGE_SIZE;

  return (
    <FindingsContext.Provider
      value={{
        searchTerm,
        setSearchTerm,
        debouncedSearchText,
        page,
        setPage,
        showFindingIds,
        setShowFindingIds,
        pageSize,
        selectedActionTab,
        setSelectedActionTab,
        selectedCommenterIds,
        setSelectedCommenterIds,
        selectedBlobIds,
        setSelectedBlobIds,
        selectedConflictCategory,
        setSelectedConflictCategory,
        selectedConflictType,
        setSelectedConflictType,
        scrollPosition,
        setScrollPosition,
        containerRef,
        pageRecords,
        setPageRecords,
        currentFinding,
        setCurrentFinding,
        getNextFinding,
        getPreviousFinding,
      }}
    >
      {children}
    </FindingsContext.Provider>
  );
};
