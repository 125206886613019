import { Button } from '@consigli/facade';
import {
  useCeilingGridId,
  useLazyGetCeilingGridBuildingJsonQuery,
  useProjectId,
} from '@consigli/hooks';
import { saveJson } from '@consigli/utils';
import { FC, useCallback } from 'react';

export const DownloadBuildingJsonButton: FC = () => {
  const projectId = useProjectId();
  const ceilingGridId = useCeilingGridId();

  const [getBuildingJSON] = useLazyGetCeilingGridBuildingJsonQuery();

  const downloadJSON = useCallback(async () => {
    const buildingJSON = await getBuildingJSON({ projectId, ceilingGridId }).unwrap();
    if (buildingJSON) {
      saveJson('updatedjson.json', buildingJSON);
    } else {
      console.error('Failed to download JSON: buildingJSON is null');
    }
  }, [ceilingGridId, getBuildingJSON, projectId]);

  return (
    <Button secondary rounded onClick={downloadJSON}>
      Download building JSON
    </Button>
  );
};
