import { mapToCamelCase, mapToSnakeCase } from '@consigli/utils';
import { z } from 'zod';
import { fileSchema } from './file';
import { getPaginationResponseSchema } from './pagination';
import { projectSchema } from './project';
export var CeilingType;
(function (CeilingType) {
    CeilingType["CEILING_GRID"] = "CEILING_GRID";
})(CeilingType || (CeilingType = {}));
export const ceilingGridSchema = z
    .object({
    id: z.string().uuid(),
    project_id: projectSchema.innerType().shape.id,
    name: z.string().min(1),
    error_message: z.array(z.string()).optional(),
    warning_message: z.array(z.string()).optional(),
    health_check: z.boolean().nullish(),
    updated_at: z.string(),
    created_at: z.string(),
    type: z.nativeEnum(CeilingType),
})
    .strict()
    .transform(mapToCamelCase);
export const ceilingGridsSchema = z.array(ceilingGridSchema).transform(mapToCamelCase);
export const createCeilingGridRequestSchema = ceilingGridSchema
    .innerType()
    .pick({
    name: true,
})
    .merge(z.object({ file_ids: z.array(fileSchema.innerType().shape.id) }))
    .strict()
    .transform(mapToSnakeCase);
export const createCeilingGridResponseSchema = ceilingGridSchema;
export const getCeilingGridFilesRequestSchema = z
    .object({
    project_id: z.number().int(),
    ceiling_grid_id: z.string().uuid(),
    page: z.union([z.number().int(), z.literal('all')]),
    page_size: z.number().int().nullish(),
    search: z.string().nullish(),
})
    .strict()
    .transform(mapToCamelCase);
export const getCeilingGridFilesResponseSchema = getPaginationResponseSchema(fileSchema);
export const getCeilingGridsResponseSchema = z.array(ceilingGridSchema).transform(mapToCamelCase);
export const getCeilingGridsRequestSchema = z
    .object({
    project_id: z.number().int(),
    type: z.nativeEnum(CeilingType).optional(),
})
    .strict()
    .transform(mapToCamelCase);
