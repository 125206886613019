export enum RoomCategory {
  STAIRS = 'STAIRS',
  CARPARK = 'CARPARK',
  ELEVATOR = 'ELEVATOR',
  PLANT_ROOM = 'PLANT_ROOM',
  RISER = 'RISER',
  CORRIDOR = 'CORRIDOR',
  HALLWAY = 'HALLWAY',
  PRINT = 'PRINT',
  STORAGE = 'STORAGE',
  ARCHIVE = 'ARCHIVE',
  DELIVERY_BAY = 'DELIVERY_BAY',
  TOILET = 'TOILET',
  TOILET_ANTEROOM = 'TOILET_ANTEROOM',
  CLEANERS_CUPBOARD = 'CLEANERS_CUPBOARD',
  OFFICE = 'OFFICE',
  GROUP_ROOM = 'GROUP_ROOM',
  MEETING_ROOM = 'MEETING_ROOM',
  CONVERSATION_ROOM = 'CONVERSATION_ROOM',
  FOCUS_ROOM = 'FOCUS_ROOM',
  TELEPHONE_ROOM = 'TELEPHONE_ROOM',
  MULTI_PURPOSE_HALL = 'MULTI_PURPOSE_HALL',
  MULTI_PURPOSE_ROOM = 'MULTI_PURPOSE_ROOM',
  AUDITORIUM = 'AUDITORIUM',
  CANTEEN = 'CANTEEN',
  DOCTOR_OFFICE = 'DOCTOR_OFFICE',
  MEDICINE_ROOM = 'MEDICINE_ROOM',
  SOCIAL_AREA = 'SOCIAL_AREA',
  COMMON_AREA = 'COMMON_AREA',
  CLASS_ROOM = 'CLASS_ROOM',
  CLASS_ROOM_ART = 'CLASS_ROOM_ART',
  CLASS_ROOM_DRY = 'CLASS_ROOM_DRY',
  CLASS_ROOM_WET = 'CLASS_ROOM_WET',
  CLASS_ROOM_DUSTY = 'CLASS_ROOM_DUSTY',
  CLASS_ROOM_MUSIC = 'CLASS_ROOM_MUSIC',
  LOCKER_ROOM = 'LOCKER_ROOM',
  DRESSING_ROOM = 'DRESSING_ROOM',
  MUSIC = 'MUSIC',
  LIBRARY = 'LIBRARY',
  DRY_ROOM = 'DRY_ROOM',
  APARTMENT = 'APARTMENT',
  FACTORY = 'FACTORY',
  DINING_ROOM = 'DINING_ROOM',
  KITCHEN = 'KITCHEN',
  BEDROOM = 'BEDROOM',
  BATHROOM = 'BATHROOM',
  LIVING_ROOM = 'LIVING_ROOM',
}

export interface CompleteCeilingJSON {
  floors: FloorData[];
  // designCriteria: DesignCriteria[];
}

export interface DesignCriteriaTEK {
  m2: number;
  pers: number;
  m3: number;
}
// export interface DesignCriteria {
//   roomCategory: RoomCategory;
//   TEK: DesignCriteriaTEK;
//   fixedReturn: number;
//   LUX: number;
// }

export interface FloorData {
  floorName: string;
  uuid: string;
  rooms: RoomData;
  doors: DoorData;
  windows: WindowData;
}

export interface Point {
  x: number;
  y: number;
}

export interface ExteriorPoints {
  exterior: Point[];
}

export interface RoomType {
  polygonPts: ExteriorPoints;
  roomId: number;
  roomCategory: string | null;
  lowerCeilingZ: number | null;
  ignoreCeiling: boolean;
  centroid: Point;
}

export interface RoomData {
  [key: string]: RoomType;
}

export interface DoorType {
  polygonPts: ExteriorPoints;
  id: number;
}

export interface DoorData {
  [key: string]: DoorType;
}

export interface WindowType {
  polygonPts: ExteriorPoints;
  id: number;
}

export interface WindowData {
  [key: string]: WindowType;
}

export interface ViewBox {
  x: number;
  y: number;
  width: number;
  height: number;
}

export const pointsToPath = (points: Point[]) => {
  const path =
    points
      .map((point, index) => {
        const prefix = index === 0 ? 'M' : 'L';
        return `${prefix} ${point.x},${point.y}`;
      })
      .join(' ') + ' Z';
  return path;
};
